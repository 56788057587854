@media screen and (max-width: 1499px) {
  .main-dashboard {
    padding: 0 25px;
  }

  .dashboard-customers {
    width: 50%;
    overflow-x: auto;
  }

  .dashboard-left .dashboard-order-details .dashboard-orders,
  .dashboard-drivers {
    width: 650px;
  }

  .table-data-main {
    width: 100%;
    display: inline-block;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table-data {
    width: 1320px;
  }

  .action-main {
    top: -42px;
    right: 70px;
  }
  .order-tracking-main .tracking-left .details .input-group {
    flex-wrap: wrap;
  }
  .order-tracking-main .tracking-left .details .input-group .input-field {
    width: 47%;
  }
  .dashboard-left
    .dashboard-main-details
    .dashboard-details-right
    .user-graph
    ul {
    flex-wrap: wrap;
  }
  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col
    .dashboard-details-heading {
    padding: 15px;
  }
  .dashboard-left .dashboard-main-details {
    flex-wrap: wrap;
  }
  .dashboard-left .dashboard-main-details .dashboard-details-left {
    width: 100%;
  }
  .dashboard-left .dashboard-main-details .dashboard-details-right {
    width: 100%;
  }
  .dashboard-left .dashboard-main-details-second {
    flex-wrap: unset;
  }
  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col {
    width: 49%;
  }
  .dashboard-left
    .dashboard-main-details-second
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col {
    width: 48%;
  }
}

@media screen and (max-width: 1365px) {
  .order-tracking-main .tracking-left .details .input-group {
    gap: 25px;
  }
  .order-tracking-main .tracking-left .head,
  .order-tracking-main .tracking-left .details {
    padding: 20px;
  }
  .dashboard-left .dashboard-main-details {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 18px;
  }

  .dashboard-left .dashboard-main-details .dashboard-details-left {
    width: 100%;
  }

  .dashboard-left .dashboard-main-details .dashboard-details-right {
    width: 100%;
  }

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col {
    gap: 18px;
  }

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col {
    width: 49%;
  }

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col
    .dashboard-details-heading {
    height: auto;
  }

  .broadcast-main .setting-details .basic-detail .profile-upload .profile-img {
    width: auto !important;
    height: auto !important;
  }
  .setting-main .setting-details .basic-detail .profile-upload .profile-img {
    width: 120px;
    height: 120px;
  }

  .setting-main .setting-details {
    padding: 20px;
  }

  .setting-main .setting-details .basic-detail .profile-details {
    width: calc(100% - 120px);
  }

  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail
    .content-detail
    h4,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail
    .content-detail
    h4 {
    font-size: 26px;
  }
  .dashboard-left .dashboard-content-news .dashboard-details-right {
    width: 50% !important;
  }
  .dashboard-left .dashboard-content-news .dashboard-post {
    width: 48% !important;
  }
  .broadcast-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field
    textarea {
    height: 175px;
  }
}

@media screen and (max-width: 1279px) {
  .dashboard-input-left {
    max-width: 430px;
  }

  .admin-dashboard.menu-active {
    position: fixed;
  }

  /* login pages */
  .dashboard-input-left {
    max-width: 430px;
  }

  .main-dashboard.dashboard-active {
    width: 100%;
  }

  .dashboard-input-left .login-back-btn {
    top: 30px;
  }

  .bar-btn-mobile {
    display: inline-block;
  }

  .logo-mobile {
    display: inline-block;
  }

  .dashboard-header .dashboard-heading {
    width: 100%;
  }

  .dashboard-header {
    flex-wrap: wrap;
    /* margin: 20px 0 15px; */
  }

  .bar-btn-mobile {
    display: inline-block;
    width: auto;
    order: 1;
  }

  .dashboard-header .dashboard-heading {
    width: 100%;
    order: 4;
    margin: 30px 0 0;
  }

  .logo-mobile {
    display: inline-block;
    width: auto;
    order: 2;
  }

  .dashboard-header .profile-picture {
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
    order: 3;
  }

  .main-dashboard {
    width: 100%;
    padding: 0 15px;
  }

  .dashboard-menu {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1111;
    height: 100vh;
  }

  .dashboard-menu .logo {
    justify-content: right;
  }

  .dashboard-menu.menu-active {
    opacity: 1;
    visibility: visible;
    left: 0;
    overflow-y: auto;
  }

  .overlay.menu-active {
    opacity: 1;
    visibility: visible;
  }

  .dashboard-menu .logo .tab-menu-bar a.barBtn {
    display: none;
  }

  .dashboard-menu .logo .tab-menu-bar a.closeBtn-mobile {
    display: unset;
  }

  .dashboard-menu.menu-active .logo .logo-img {
    display: none;
  }

  .dashboard-menu.menu-active .dashboard-options {
    margin: 30px 0 0;
  }

  .admin-dashboard.menu-active {
    position: fixed;
    height: 100%;
  }

  .setting-main .setting-details {
    max-width: inherit;
  }
}

@media screen and (max-width: 1099px) {
  /* .dashboard-input-left .logo {
        margin: 0 0 45px 0px;
    } */

  /* login pages */
  .property-main .property-details {
    padding: 30px 15px;
  }
  .property-main .property-head {
    padding: 13px 15px !important;
  }
  .property-main .property-head h3 {
    font-size: 20px;
  }
  .property-main .property-details .property-box {
    padding: 0 15px;
  }
  .property-add-main {
    padding: 30px 0;
  }

  .dashboard-input-left {
    max-width: 390px;
  }

  .dashboard-input-left .login-input-details .input-group input {
    height: 55px;
  }

  .dashboard-input-left .login-input-details .login-btn button {
    height: 55px;
  }

  .dashboard-input-left
    .login-input-details
    .input-group
    input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .dashboard-input-left .login-input-details .otp-input input {
    width: 55px;
    height: 55px;
  }

  #Dashboard-login-main {
    height: 100vh;
  }

  .setting-main .setting-details {
    max-width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .dashboard-input-left .login-input-details .login-btn button {
    height: 55px;
  }

  .dashboard-input-left .login-input-details .input-group input {
    height: 55px;
  }

  .dashboard-input-left
    .login-input-details
    .input-group
    input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }

  /* login pages */
  .dashboard-input-left .login-back-btn {
    top: 20px;
  }

  .feedback-main {
    flex-wrap: wrap;
  }

  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 991px) {
  .order-tracking-main .tracking-right {
    margin-left: auto;
  }
  .order-tracking-main {
    flex-wrap: wrap;
  }
  .order-tracking-main .tracking-left .head {
    padding: 20px;
  }
  .order-tracking-main .tracking-left {
    width: 100%;
  }
  .order-tracking-main .tracking-left .details .input-group {
    justify-content: space-between;
  }
  .order-tracking-main .tracking-left .order-photos .img span {
    width: 150px;
    height: 150px;
  }
  .order-tracking-main {
    width: 100%;
  }
  .lendors-main .setting-details .basic-detail .profile-details .borrow-head {
    max-width: 100%;
  }
  .lendors-main .setting-details .basic-detail .profile-details .input-group {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .borrow-main .setting-details .basic-detail {
    gap: 30px;
  }
  .lendors-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field {
    width: 47%;
  }
  .setting-main .setting-details .basic-detail .profile-upload label {
    font-size: 12px;
    margin: 5px 0 0;
  }
  .information-main .info-head {
    padding: 23px 15px;
  }
  .add-area-info {
    padding: 29px 15px;
  }
  .property-main .property-details {
    flex-wrap: wrap;
    gap: 25px;
  }
  .property-main .property-details .property-box {
    width: 48%;
  }
  .property-add-main a.add-property {
    width: 48%;
    padding: 25px;
  }
  .property-add-main a.add-property .icon {
    width: 54px;
    height: 54px;
  }
  .property-add-main a.add-property .icon img {
    width: 22px;
  }
  .dashboard-header {
    padding: 21px 0;
  }
  .property-add-main {
    flex-wrap: wrap;
    gap: 25px;
  }
  .information-main .info-details .add-house button {
    width: 135px;
    height: 45px;
    font-weight: 500;
  }
  .information-main .info-details .house-img-main .upload-img-main .upload-img {
    width: 120px;
    height: 120px;
  }
  .broadcast-main {
    height: 100vh !important;
  }
  .broadcast-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field
    textarea {
    height: 147px;
  }
  .setting-main .setting-details .save-btn {
    margin: 30px 0 0;
  }
  .logo-mobile a img {
    width: 130px;
  }
  .login-img-right {
    display: none;
  }

  .dashboard-input-left {
    margin: 45px auto 0;
    text-align: center;
  }

  .dashboard-input-left .login-input-details .input-group {
    justify-content: center;
  }

  /* .dashboard-input-left .logo a img {
        width: 200px;
    } */

  .dashboard-input-left .logo {
    margin: 0 0 20px 0;
  }

  .dashboard-input-left .login-input-details .otp-input input {
    width: 60px;
    height: 60px;
  }

  /* login pages */
  .login-img-right {
    display: none;
  }

  .dashboard-input-left .login-back-btn {
    top: 10px;
  }

  .dashboard-input-left .logo a img {
    width: 265px;
  }

  .dashboard-input-left {
    width: 100%;
  }

  .dashboard-input-left .login-input-details .input-group {
    justify-content: center;
  }

  .dashboard-input-left {
    text-align: center;
  }

  .dashboard-input-left .login-input-details p {
    margin-bottom: 20px;
  }

  .dashboard-input-left {
    max-width: 430px;
  }

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col {
    width: 48%;
  }

  .dashboard-left .dashboard-main-details .dashboard-details-right {
    width: 100%;
  }

  .vendor-main {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .vendor-main .vendor-information {
    width: 100%;
    max-width: inherit;
  }

  .vendor-main
    .vendor-information
    .basic-detail
    .profile-details
    .block-btn
    button {
    width: 40px;
    height: 40px;
  }

  .vendor-main
    .vendor-information
    .basic-detail
    .profile-details
    .block-btn
    button
    img {
    width: 16px;
  }

  .vendor-main {
    height: auto;
  }

  .information-main .info-details .input-group {
    flex-wrap: wrap;
    gap: 21px;
  }

  .information-main .info-details .input-group .input-field {
    width: 48%;
  }

  .setting-main .setting-details .basic-detail .profile-details {
    width: 100%;
  }

  .reject-services-main {
    width: 700px;
  }

  .create-admin-main {
    width: 680px;
  }

  .create-admin-main .admin-profile-main .admin-profile {
    width: 140px;
  }

  .create-admin-main .admin-profile-main .admin-profile img {
    height: 140px;
  }

  .create-admin-main .admin-profile-main {
    flex-wrap: wrap;
  }

  .create-admin-main .admin-profile-main .admin-detail {
    width: 100%;
  }

  .contact-detail .setting-details {
    max-width: 100% !important;
  }

  .contact-detail
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field {
    width: 50%;
  }

  .services-main .service-type-main .service {
    width: 100%;
  }

  .loaction-list-main .request-selection ul {
    width: max-content;
  }

  .loaction-list-main .request-selection {
    max-width: 900px;
    overflow-x: scroll;
  }

  .doctors-details .doc-main .doc {
    width: max-content;
  }

  .loaction-list-main .blog-detail-main .content .video-content {
    flex-wrap: wrap;
  }

  .loaction-list-main .blog-detail-main .content .video-content .thumbnail {
    width: 100%;
  }

  .loaction-list-main .blog-detail-main .content .video-content .video-file {
    width: 100%;
  }
  .dashboard-left .dashboard-main-details .dashboard-details-left {
    width: 100%;
  }
  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col {
    justify-content: space-between;
    margin: 0 0 20px;
  }
  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col {
    padding: 0;
  }
  .dashboard-left .dashboard-content-news .dashboard-details-right,
  .dashboard-left .dashboard-content-news .dashboard-post {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .dashboard-input-left {
    width: 100%;
  }

  .dashboard-input-left .login-input-details .otp-input input {
    width: 55px;
    height: 55px;
  }

  .customer-detail .setting-details .basic-detail {
    flex-wrap: wrap;
    gap: 20px;
  }

  .setting-main .setting-details .basic-detail .profile-details {
    width: 100%;
  }

  .creat-main,
  .creat-category-main {
    max-width: 580px;
  }

  .setting-main .setting-details .basic-detail {
    flex-wrap: wrap;
    padding: 0 0 25px;
  }

  .loaction-list-main .loaction-list-head {
    flex-wrap: wrap;
  }

  .loaction-list-head button.Export-btn {
    margin: 20px 0;
  }

  .reject-services-main {
    width: 576px;
  }

  .create-admin-main {
    width: 550px;
  }

  .create-admin-main .create-head {
    width: 100%;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .input-group {
    flex-wrap: wrap;
  }

  .setting-main .setting-details .save-btn {
    margin: 0;
  }

  .contact-detail
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field {
    width: 100%;
  }

  .setting-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field {
    width: 100%;
  }

  .request-document .doc-main .doc {
    width: 165px;
  }

  .request-document .doc-main .doc .Document-name {
    padding: 15px 0;
  }
}

@media screen and (max-width: 639px) {
  .loaction-list-head ul {
    width: 520px;
    overflow-x: auto;
  }

  .loaction-list-head ul li a {
    padding: 15px 0;
  }

  .dashboard-left .dashboard-order-details {
    flex-wrap: wrap;
  }

  .dashboard-customers {
    width: 100%;
  }

  .loaction-list-main .loaction-list-head {
    padding: 15px 15px 0 15px;
    flex-wrap: wrap;
  }

  .loaction-list-main .loaction-list-head .add-location {
    margin: 20px 0 0;
  }

  .loaction-list-main .loaction-list-head .add-location {
    width: 100%;
  }

  .next-previous-slide {
    flex-wrap: wrap;
    justify-content: center;
  }

  .next-previous-slide .slides-button {
    width: 100%;
    text-align: center;
    margin: 25px 0 0;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .input-group {
    flex-wrap: wrap;
    margin: 0 0 25px;
  }

  .setting-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field {
    width: 100%;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .customer-info
    .block-btn
    button {
    width: 40px;
    height: 40px;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .customer-info
    .block-btn
    button
    img {
    width: 16px;
  }

  .customer-detail .setting-details .basic-detail .profile-details .sms-update {
    width: 255px;
  }

  .block-main,
  .logout-main {
    max-width: 300px;
    padding: 15px 26px 20px;
  }

  .information-main .info-details .input-group .input-field {
    width: 100%;
  }

  .information-main .info-details {
    padding: 25px;
  }

  .information-main .info-head {
    padding: 23px 25px;
  }

  .feedback-main .employee-feed .employee-box .service-pictures .services-img {
    flex-wrap: wrap;
  }

  .feedback-main .employee-feed .employee-head {
    padding: 23px 25px;
  }

  .feedback-main .employee-feed .employee-box {
    padding: 25px;
  }

  .feedback-main .employee-feed .employee-box .service-pictures {
    margin: 25px 0 0;
  }

  .feedback-main .employee-feed .employee-box .customer-rating {
    margin: 25px 0 0;
  }

  .main-dashboard .dashboard-table-head {
    flex-wrap: wrap;
  }

  .add-recovery-btn,
  .add-subadmin-btn {
    width: 100%;
    margin: 20px 0 0;
  }

  .doctors-details .doc-main .doc {
    width: 100%;
    padding: 15px;
  }

  .request-document .doc-main .doc .doc-file {
    height: 150px;
  }
}

@media screen and (max-width: 575px) {
  .dashboard-input-left {
    padding: 0 15px;
    margin: 0;
  }

  .dashboard-input-left .login-input-details .login-btn button {
    height: 45px;
  }

  .dashboard-input-left .login-input-details .input-group input {
    height: 45px;
    padding: 0 40px;
  }

  .dashboard-input-left .login-input-details .input-group span.icon img {
    width: 20px;
  }

  .dashboard-input-left
    .login-input-details
    .input-group
    span.icon
    img.mobile-icon {
    width: 16px;
  }

  .dashboard-input-left .login-input-details .input-group span.icon {
    left: 4px;
  }

  .dashboard-input-left .logo {
    margin: 0 0 10px 0;
  }

  /* .dashboard-input-left .logo a img {
        width: 170px;
    } */

  .dashboard-input-left .login-input-details p {
    margin: 8px 0 25px 0;
  }

  .dashboard-input-left .login-input-details .otp-input input {
    width: 45px;
    height: 45px;
  }

  /* login pages */

  .dashboard-input-left .logo {
    margin: 0 0 28px 0px;
  }

  /* 
    .dashboard-input-left .logo a img {
        width: 150px;
    } */

  .dashboard-input-left .login-input-details h2 {
    font-size: 22px;
  }

  .dashboard-input-left .login-input-details p {
    font-size: 14px;
    margin: 8px 0 25px 0;
  }

  .information-main .info-details .input-group.my {
    margin: 0 0 0;
  }

  .dashboard-input-left .login-input-details .input-group input {
    height: 45px;
    padding: 0 45px;
  }

  .dashboard-input-left .login-input-details .login-btn button {
    height: 45px;
  }

  .dashboard-input-left .login-input-details .otp-input input {
    width: 40px;
    height: 40px;
  }

  .dashboard-input-left .login-input-details .input-group span.icon {
    left: 8px;
  }

  .dashboard-input-left .login-input-details .input-group span.icon img {
    width: 20px;
  }

  .dashboard-input-left
    .login-input-details
    .input-group
    span.icon
    img.mobile-icon {
    width: 15px;
  }

  .dashboard-input-left .login-input-details .resend-otp p a {
    margin: 0;
    font-size: 14px;
  }

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col
    .dashboard-details-col {
    width: 100%;
    padding: 0;
  }

  .dashboard-left .dashboard-main-details .dashboard-details-right {
    width: 100%;
    flex-wrap: wrap;
  }
  .dashboard-left
    .dashboard-main-details
    .dashboard-details-right
    .dashboard-graph-main {
    width: 100%;
  }
  .dashboard-left .dashboard-main-details .dashboard-details-right .user-graph {
    width: 100%;
  }

  .dashboard-header .dashboard-heading h2,
  .dashboard-header .dashboard-heading a.dashboard-back,
  .main-dashboard .dashboard-table-head h2,
  .services-main .service-type-main .service .head h2 {
    font-size: 22px;
  }

  .filter-btn {
    width: 100%;
    margin: 15px 9px 0 0;
  }

  .filter-main {
    width: 300px;
  }

  .filter-main .filter-status .input-group input {
    width: 18px;
  }

  .filter-main .filter-apply button {
    height: 40px;
    font-size: 14px;
  }

  .creat-main,
  .creat-category-main {
    max-width: 320px;
    padding: 15px;
  }

  .creat-main .input-group {
    flex-wrap: wrap;
  }

  .creat-main .input-group .input-field {
    width: 100%;
  }

  .creat-main .input-group .input-field input {
    height: 40px;
  }

  .creat-main .creat-btn button {
    height: 40px;
    font-size: 14px;
  }

  .customer-profile img {
    width: 40px;
    height: 40px;
  }

  td.table-col {
    padding: 20px;
  }

  .setting-main .setting-details .basic-detail .profile-details {
    width: 100%;
  }

  .vendor-main .vendor-information .basic-detail {
    flex-wrap: wrap;
  }

  .vendor-main .vendor-information .vendor-details .input-group .input-field {
    width: 100%;
  }

  .vendor-main .vendor-information .vendor-details .input-group {
    flex-wrap: wrap;
  }

  .setting-main .setting-details .basic-detail .profile-details .input-group {
    flex-wrap: wrap;
    margin: 0 0 20px;
  }

  .setting-main {
    height: 100vh;
  }

  .setting-main .setting-details .basic-detail {
    padding: 0 0 0;
    margin: 0 0;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .customer-info {
    flex-wrap: wrap;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .customer-info
    .customer-name {
    margin: 0;
  }

  .setting-main .setting-details p {
    line-height: 20px;
    margin: 0 0 15px;
  }

  .setting-main
    .setting-details
    .basic-detail
    .profile-upload
    .profile-img
    .input-file {
    width: 35px;
    height: 35px;
  }

  .setting-main
    .setting-details
    .basic-detail
    .profile-upload
    .profile-img
    .input-file
    img {
    width: 18px;
  }

  .setting-main .setting-details .save-btn button,
  .service-info .add-services-btn button {
    height: 40px;
    font-weight: 500;
  }

  .loaction-list-main .loaction-list-head {
    padding: 0 15px 0 15px;
  }

  .information-main .info-details,
  .feedback-main .employee-feed .employee-box {
    padding: 20px 15px 15px 15px;
  }

  .information-main .info-head,
  .feedback-main .employee-feed .employee-head {
    padding: 15px;
  }

  .feedback-main .employee-feed .employee-box .service-pictures .services-img,
  .feedback-main .employee-feed .employee-box .service-pictures .services-head {
    gap: 10px;
  }

  .service-product-main {
    width: 320px;
    height: 500px;
    overflow-y: auto;
  }

  .service-product-main .product-head {
    padding: 15px;
  }

  .service-product-main .product-details {
    padding: 15px;
  }

  .service-img-main {
    margin: 20px 0 0;
  }

  .service-img-main .product-upload {
  }

  .service-img-main .product-upload .uploaded-img {
    width: 110px;
  }

  .service-img-main .product-upload .uploaded-img img {
    height: 110px;
  }

  .service-img-main .product-upload .add-img {
    width: 110px;
    height: 110px;
  }

  .service-product-main .product-btn {
    padding: 15px;
  }

  .service-product-main .product-btn button,
  .information-main .info-details .complete-btn button,
  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .save-btn
    button {
    height: 40px;
  }

  .feedback-main
    .employee-feed
    .employee-box
    .service-pictures
    .services-img
    .img-col {
    width: 110px;
  }

  .feedback-main
    .employee-feed
    .employee-box
    .service-pictures
    .services-img
    .img-col
    img {
    height: 110px;
  }

  .feedback-main
    .employee-feed
    .employee-box
    .service-pictures
    .services-img
    .img-col
    span.delete-icon {
    bottom: 12px;
  }

  .customer-detail .setting-details .basic-detail .profile-details .save-btn {
    margin: 30px 0 0;
  }

  .setting-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field
    input {
    height: 45px;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .input-group.mb {
    margin: 0 0 25px;
  }

  .setting-main {
    height: auto;
  }

  .clients-details-head .dashboard-heading {
    margin: 15px 0 0;
  }

  .setting-main .setting-details .save-btn {
    margin: 10px 0 0;
  }

  .main-dashboard {
    height: 100vh;
  }

  .clients-details-head {
    height: auto;
  }

  .clients-main-dashboard,
  .service-main-dashboard,
  .complete-service-main {
    height: auto;
  }

  .loaction-list-main .loaction-list-head .search-bar {
    max-width: none;
  }

  .loaction-list-main .loaction-list-head .search-bar input {
    width: 270px;
  }

  .loaction-list-main .loaction-list-head .add-service {
    margin: 0 0 15px;
  }

  .service-info
    .feedback-main
    .employee-feed
    .employee-box
    .service-pictures
    .services-img
    .img-col {
    width: 110px;
    height: 110px;
  }

  .service-info .add-services-btn {
    margin: 30px 0 0;
  }

  .time-period-main .timing {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .reject-services-main {
    width: 320px;
    padding: 15px;
  }

  .reject-services-main .service-head {
    margin: 0 0 30px;
  }

  .reject-services-main .input-group {
    margin: 0 0 20px;
  }

  .reject-services-main .reject-btn button {
    height: 40px;
  }

  .time-period-main .timing span {
    width: 100%;
  }

  .time-period-main .timing .start-time,
  .time-period-main .timing .end-time {
    width: 100%;
  }

  .change-password-main {
    width: 90%;
    padding: 15px;
  }

  .change-password-main .password-btn button {
    height: 40px;
  }

  .main-dashboard .dashboard-table-head a {
    font-size: 16px;
  }

  /* .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col .dashboard-details-heading {
        margin: 0 0 20px;
    } */

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-left
    .dashboard-main-col {
    margin: 0;
  }

  .dashboard-input-left .login-input-details .input-group a {
    margin: 0 0 15px;
  }

  #Dashboard-login-main {
    justify-content: center;
  }

  .otp-main,
  .password-change-main,
  .recovery-home-add-main {
    width: 360px;
    top: 15px;
  }

  .otp-main .otp,
  .password-change-main .otp,
  .recovery-home-add-main .otp {
    padding: 15px;
  }

  .otp-main,
  .password-change-main,
  .recovery-home-add-main {
    width: 320px;
    top: 15px;
  }

  .dashboard-input-left .login-input-details .save-btn {
    margin: 20px 0 0;
  }

  .information-main .info-details .house-img-main .house-head h2 {
    flex-wrap: wrap;
  }

  .information-main .info-details .house-img-main .house-head h2 span {
    width: 100%;
    margin: 5px 0 7px 0;
  }

  .information-main .info-details .house-img-main .upload-img-main .upload-img {
    width: 80px;
    height: 80px;
  }

  .information-main .info-details .add-house button {
    font-size: 14px;
    height: 40px;
    width: 120px;
  }

  .information-main .info-details .house-img-main .upload-img-main {
    gap: 25px;
    justify-content: space-between;
  }

  .information-main
    .info-details
    .house-img-main
    .upload-img-main
    .upload-img
    button.edit-img {
    width: 40px;
    height: 40px;
  }

  .create-admin-main {
    width: 320px;
    height: 500px;
    overflow-y: auto;
  }

  .create-admin-main .admin-profile-main .admin-detail .input-group {
    flex-wrap: wrap;
  }

  .create-admin-main
    .admin-profile-main
    .admin-detail
    .input-group
    .input-field {
    width: 100%;
  }

  .create-admin-main .create-btn button {
    width: 190px;
    height: 40px;
    font-size: 14px;
  }

  .dashboard-header .profile-picture .profile-img a img {
    width: 45px;
    height: 45px;
  }

  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .customer-info
    .block-btn
    button,
  .customer-detail
    .setting-details
    .basic-detail
    .profile-details
    .customer-info
    .edit-btn
    button,
  .setting-details .edit-btn button {
    width: 40px;
    height: 40px;
  }

  .next-previous-slide {
    margin: 20px 0;
  }

  .subadmin-dashboard {
    height: 100%;
  }

  .main-dashboard .dashboard-table-head {
    margin: 20px 0 31px;
  }

  .dashboard-left .dashboard-main-details .dashboard-details-left .head {
    padding: 15px 5px;
  }

  .dashboard-left
    .dashboard-main-details
    .dashboard-details-right
    .graph
    .graph-head
    h4,
  .dashboard-left .dashboard-main-details .dashboard-details-left .head h4 {
    font-size: 18px;
  }

  .services-main .service-type-main .service .head {
    padding: 13px 0 13px 15px;
  }

  .services-main .service-type-main .service .id {
    padding: 22px 15px;
  }

  .services-main .service-type-main .service ul li {
    padding: 13px 15px;
  }

  .services-main .service-btn {
    gap: 10px;
    justify-content: left;
  }

  .add-service-main,
  .add-insurance-main {
    width: 300px;
  }

  .loaction-list-main .request-selection ul li {
    padding: 15px 35px 15px 0;
    font-size: 16px;
  }

  .request-main .setting-details .basic-detail .profile-details .profile-head {
    flex-wrap: wrap;
  }

  .request-document h3,
  .doctors-details h3 {
    font-size: 22px;
  }

  .request-main
    .setting-details
    .basic-detail
    .profile-details
    .profile-head
    .reject-approve-btn
    button {
    width: 110px;
    height: 40px;
  }

  .logo-mobile a img {
    width: 150px;
    padding: 15px 0 0;
  }

  .dashboard-header {
    padding: 20px 0 15px;
  }

  .loaction-list-main .blog-head {
    padding: 11px 15px;
  }

  .loaction-list-main .blog-head .right button,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .thumbnail-img
    button.edit-btn,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .thumbnail-video
    button.edit-btn {
    width: 35px;
    height: 35px;
  }

  .loaction-list-main .blog-head .right button img,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .thumbnail-img
    button.edit-btn
    img,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .thumbnail-video
    button.edit-btn
    img {
    width: 15px;
  }

  .loaction-list-main .blog-detail-main {
    padding: 15px;
  }

  .loaction-list-main .blog-detail-main .title input {
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
  }

  .loaction-list-main .blog-detail-main .content .content-data {
    padding: 15px;
  }

  .loaction-list-main .blog-detail-main .content .content-data p {
    font-size: 14px;
  }

  .loaction-list-main .blog-detail-main .upload-blog button {
    height: 40px;
    width: 140px;
    font-size: 13px;
  }

  .dashboard-header .dashboard-heading a.dashboard-back img {
    margin: 0 10px 0 0;
    width: 15px;
  }

  .loaction-list-main .blog-detail-main .content {
    margin: 20px 0 0;
  }

  .bar-btn-mobile span {
    width: 45px;
    height: 45px;
  }

  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail {
    padding: 15px;
    border-radius: 10px;
  }

  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail
    .content-detail
    h4,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail
    .content-detail
    h4 {
    font-size: 18px;
  }

  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail
    .content-detail
    h4
    img,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail
    .content-detail
    h4
    img {
    width: 25px;
  }

  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail
    .content-detail
    p,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail
    .content-detail
    p,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .thumbnail
    .upload-thumbnail
    .content-detail
    span,
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .upload-thumbnail
    .content-detail
    span {
    font-size: 16px;
    margin: 10px 0 65px;
  }
  .loaction-list-main .blog-detail-main .content .video-content {
    gap: 24px;
  }
  .loaction-list-main
    .blog-detail-main
    .content
    .video-content
    .video-file
    .thumbnail-video
    button.play-btn
    img {
    width: 60px;
  }
  .dashboard-input-left .logo a img {
    width: 170px;
    height: auto;
  }
  .logo-mobile a img {
    width: 110px;
    padding: 0;
  }
  .broadcast-main .setting-details .basic-detail .profile-upload .profile-img {
    width: 100px !important;
    height: 100px !important;
  }
  .broadcast-main .setting-details .basic-detail .profile-details .input-group {
    margin: 0;
  }
  .broadcast-main .setting-details .basic-detail .profile-upload label {
    margin: 0 0 5px;
    font-size: 12px;
  }
  .broadcast-main .setting-details .basic-detail {
    padding: 0 0 0;
    margin: 0 0;
    gap: 10px;
  }
  .broadcast-main
    .setting-details
    .basic-detail
    .profile-details
    .input-group
    .input-field
    textarea {
    height: 100px;
  }
  .information-main .info-details .add-info .note-info {
    width: 100%;
  }
  .information-main .info-details .add-info {
    row-gap: 15px;
  }
  .information-main .info-head .service-btn button {
    width: 35px;
    height: 35px;
  }
  .information-main .info-head .service-btn button img {
    width: 18px;
  }
  .information-main .info-head .service-btn button.delete-btn img {
    width: 12px;
  }
  .information-main .info-head .service-btn {
    gap: 5px;
  }
  .information-main .info-head h2 {
    font-size: 18px;
  }
  .property-main .property-details .property-box {
    width: 100%;
  }
  .property-add-main a.add-property {
    width: 100%;
    padding: 15px;
  }
  .property-add-main a.add-property span {
    font-size: 18px;
  }
  .property-add-main a.add-property .icon {
    width: 45px;
    height: 45px;
  }
  .property-add-main a.add-property .icon img {
    width: 20px;
  }
  .property-add-main {
    padding: 20px 0;
  }
  .loaction-list-main .loaction-list-head .search-bar input {
    width: 100%;
  }
  .property-area-table-data {
    width: 600px;
  }
  .add-area-info {
    padding: 15px;
  }
  .add-area-info .area-name {
    margin: 0 0 15px;
  }
  .add-area-info input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
  }
  .add-area-info .select-steerts .search-street {
    width: 100%;
  }
  .add-area-info .streent-areas-select .street-area-left .street-label {
    gap: 5px;
  }
  .add-area-info .streent-areas-select .street-label input {
    width: 13px;
    height: 13px;
  }
  .create-category-main {
    width: 90%;
    padding: 25px 15px 15px;
  }
  .create-category-main .head {
    margin: 0 0 15px;
  }
  .create-category-main .head p {
    margin: 6px 0 15px;
  }
  .create-category-main .input-field input {
    height: 45px;
  }
  .create-category-main .create-btn {
    width: 120px;
    height: 45px;
    font-weight: 500;
    font-size: 13px;
  }
  .dashboard-header .dashboard-heading {
    margin: 20px 0 0;
  }
  .setting-main .setting-details h3 {
    margin: 0 0 15px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }
  .add-area-btn button {
    height: 40px;
    font-weight: 500;
  }
  .borrow-main .setting-details .basic-detail {
    row-gap: 30px;
  }
  .lendors-main
    .setting-details
    .basic-detail
    .profile-details
    .borrow-head
    .left
    h2 {
    flex-wrap: wrap;
  }
  .borrow-main
    .setting-details
    .basic-detail
    .profile-details
    .borrow-head
    .right
    button {
    width: 45px;
    height: 45px;
  }
  .lendors-main
    .setting-details
    .basic-detail
    .profile-details
    .borrow-head
    .left {
    width: 70%;
  }
  .borrow-main
    .setting-details
    .basic-detail
    .profile-details
    .borrow-head
    .left {
    width: 63%;
  }
  .order-tracking-main .tracking-left .details .input-group .input-field {
    width: 100%;
  }
  .order-tracking-main
    .tracking-left
    .details
    .input-group
    .input-field.reason {
    width: 100%;
  }
  .order-tracking-main .tracking-left .head,
  .order-tracking-main .tracking-left .details {
    padding: 15px;
  }
  .order-tracking-main .tracking-left .order-photos .img span {
    width: 80px;
    height: 80px;
  }
  .order-tracking-main .tracking-left .head h3 {
    font-size: 18px;
  }
  .order-tracking-main .tracking-left .details .input-group .input-field input {
    height: 45px;
  }
  .order-tracking-main .tracking-right .lendor-info .send-btn {
    height: 45px;
    font-weight: 500;
    font-size: 15px;
  }
  .order-tracking-main .tracking-left .details .input-group {
    margin: 0 0 30px;
  }
  .order-tracking-main .tracking-right .return-back .head {
    text-align: left;
    padding: 15px;
  }
  .order-tracking-main .tracking-right .return-back .head h4 {
    font-size: 16px;
    line-height: 16px;
  }
}
