@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

@font-face {
  font-family: "customfont";
  src: url("../../asessts/fonts/Proxima_Nova_Font.otf") format("truetype");
  /* Add similar declarations for other font weights or styles */
}

body {
  margin: 0;
  font-family: "customfont", sans-serif !important;
  background-color: #f9f9f9;
  color: #333;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none !important;
  box-sizing: border-box;
  outline: none;
}
a {
  text-decoration: none;
  color: #ffffff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(177, 172, 172, 0.5);
  z-index: 2;
  pointer-events: auto;
}

button {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

textarea {
  resize: none !important;
}

input,
textarea {
  font-family: "Montserrat", sans-serif;
}

/* change password popup */
.change-password-main {
  width: 378px;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 1111;
  padding: 29px 30px 19px 30px;
  transform: translate(-50%, -50%);
}

.change-password-main.change-active {
  opacity: 1;
  visibility: visible;
}

.change-password-main .change-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 43px;
}

.change-password-main .change-head h2 {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.change-password-main .change-head button {
  background: none;
  border: none;
}

.change-password-main .input-field {
  width: 100%;
  margin: 0 0 30px;
}

.change-password-main .input-field label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  font-family: "Rubik", sans-serif;
  margin: 0 0 8px;
}

.change-password-main .input-field .password {
  width: 100%;
  position: relative;
}

.change-password-main .input-field .password input {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0 19px;
}

.change-password-main .input-field .password span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
}

.change-password-main .password-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.change-password-main .password-btn button {
  width: 88px;
  height: 48px;
  border: none;
  background: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #413740;
}

.change-password-main .password-btn button.Save-btn {
  background: #413740;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* logout confirm popup */
.block-main,
.logout-main,
.delete-guest-main {
  width: 100%;
  max-width: 340px;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 26px 38px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 1111;
}

.delete-guest-main.delete-active {
  opacity: 1;
  visibility: visible;
}

.block-main.block-active {
  opacity: 1;
  visibility: visible;
}

.block-main.customer-block-active {
  opacity: 1;
  visibility: visible;
}

.logout-main.logout-main-active {
  opacity: 1;
  visibility: visible;
}

.block-main.delete-coupon-active {
  opacity: 1;
  visibility: visible;
}

.block-main .delete-icon,
.logout-main .delete-icon,
.delete-guest-main .delete-icon {
  width: auto;
  background: none;
  border: none;
}

.block-main .delete-icon img {
  width: 35px;
  height: 35px;
}

.delete-guest-main .delete-icon img {
  width: 27px;
}

.block-main h2,
.logout-main h2,
.delete-guest-main h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin: 12px 0 0;
}

.block-main p,
.logout-main p,
.delete-guest-main p {
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  margin: 12px 0;
}

.logout-main p {
  margin: 12px 0 30px;
}

.block-main .block-btn,
.logout .block-btn,
.delete-guest-main .block-btn {
  width: 100%;
}

.block-main .vendor-block {
  margin: 18px 0 0;
  display: inline-block;
}

.block-main .block-btn button,
.logout-main .block-btn button,
.delete-guest-main button {
  width: 80px;
  height: 36px;
  color: #413740;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  margin: 0 10px 0 0;
  border: none;
  background: none;
}

.block-main .block-btn button.logout-btn,
.logout-main .block-btn button.logout-btn,
.delete-guest-main .block-btn button.logout-btn {
  background: #413740;
  color: #fff;
}

/* Login pages css start */
#Dashboard-login-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.dashboard-input-left {
  width: 50%;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}

.dashboard-input-left .logo {
  width: 100%;
  margin: 0 0 30px 0;
}

.dashboard-input-left .logo a {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.dashboard-input-left .logo a img {
  width: 300px;
  height: auto;
}

.dashboard-input-left .login-input-details {
  width: 100%;
}

.dashboard-input-left .login-input-details h2 {
  color: #4f4f4f;
  font-size: 32px;
  font-weight: bold;
}

.dashboard-input-left .login-input-details p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 8px 0 34px 0;
}

.dashboard-input-left .login-input-details .input-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
  margin: 0 0 16px 0;
}

.dashboard-input-left .login-input-details .input-group.mb {
  margin-bottom: 12px;
}

.dashboard-input-left .login-input-details .input-group span.icon {
  position: absolute;
  top: 50%;
  left: 21px;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

.dashboard-input-left .login-input-details .input-group span.eye-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  cursor: pointer;
}

.dashboard-input-left .login-input-details .input-group input {
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 63px;
}

.dashboard-input-left .login-input-details .input-group input::placeholder {
  font-size: 16px;
  color: #bdbdbd;
}

.dashboard-input-left .login-input-details .input-group input[type="checkbox"] {
  width: 26px;
  height: 26px;
  display: inline-block;
  accent-color: #1980da;
}

.dashboard-input-left .login-input-details .input-group label {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 11px;
}

.dashboard-input-left .login-input-details .input-group a {
  color: #413740;
  font-size: 16px;
  margin: 0 0 16px;
}

.dashboard-input-left .login-input-details .login-btn {
  width: 100%;
  margin: 0 0 26px;
}

.dashboard-input-left .login-input-details .save-btn {
  margin: 43px 0 0;
}

.dashboard-input-left .login-input-details .login-btn button {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: none;
  background: #419485;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-img-right {
  width: 50%;
}

.login-img-right img {
  width: 100%;
  vertical-align: top;
  height: 100vh;
  object-fit: cover;
}

.dashboard-input-left .login-input-details .otp-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
}

.dashboard-input-left .login-input-details .otp-input input {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  outline: none;
}

.dashboard-input-left .login-input-details .resend-otp p {
  color: #4f4f4f;
  font-size: 16px;
}

.dashboard-input-left .login-input-details .resend-otp p a {
  color: transparent;
  font-size: 16px;
  margin: 0 0 0 6px;
  color: #413740;
}

.dashboard-input-left .login-input-details .resend-otp {
  width: 100%;
  text-align: left;
}

.dashboard-input-left .login-back-btn {
  width: auto;
  position: absolute;
  top: 56px;
}

.dashboard-input-left .login-back-btn a {
  width: 40px;
  height: 40px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* dashboard css start */
.admin-dashboard {
  width: 100%;
  display: flex;
  position: relative;
  background-color: #f9f9f9;
}

.admin-dashboard.change-active {
  position: fixed;
  height: 100%;
}

.admin-dashboard.block-active {
  position: fixed;
  height: 100%;
}

.admin-dashboard.product-active {
  position: fixed;
  height: 100%;
}

.dashboard-menu {
  width: 300px;
  background-color: #fff;
  padding: 0 20px;
  transition: all 0.5s;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 2px 0 1px rgba(0, 0, 0, 2%);
}

.dashboard-menu.dashboard-active {
  width: 115px;
  transition: all 0.5s;
}

.dashboard-menu.dashboard-active .logo {
  justify-content: center;
  margin: 35px 0 0;
}

.dashboard-menu.dashboard-active .logo .logo-img {
  display: none;
}

.dashboard-menu.dashboard-active .dashboard-options ul li a {
  width: 60px;
  font-size: 0;
  transition: all 0.5s;
  padding: 0 0 0 18px;
}

.dashboard-menu .logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 29px 0 0;
}

.dashboard-menu .logo .logo-img {
  width: auto;
}

.dashboard-menu .logo .logo-img a {
  display: inline-block;
  padding: 0;
}

.dashboard-menu .logo .logo-img a img {
  width: 145px;
}

.dashboard-menu .logo .tab-menu-bar a {
  display: inline-block;
}

.dashboard-menu .logo .tab-menu-bar a .fa-times {
  display: none;
}

.dashboard-menu .logo .tab-menu-bar a i {
  font-size: 22px;
}

.dashboard-menu .dashboard-options {
  width: 100%;
  margin: 45px 0 0;
}

.dashboard-menu .dashboard-options ul li a {
  color: transparent;
  text-decoration: none;
  width: 100%;
  font-weight: 600;
  min-height: 56px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  line-height: 56px;
  padding: 0 0 0 10px;
  margin: 0 0 14px;
  border-radius: 10px;
  color: #419485;
  font-size: 14px;
}

.dashboard-menu .dashboard-options ul li a span.menu-icon {
  margin: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-menu .dashboard-options ul li a.active {
  background: #419485;
  color: #fff;
}
.subadmin-dashboard {
  position: relative;
}

.dashboard-menu .dashboard-options ul li a.active img {
  filter: brightness(0) invert(1);
}

.dashboard-menu .dashboard-options ul li a span {
  margin: 0 0 0 12px;
  display: inline-block;
}
.table-data-main {
  position: relative;
}

.main-dashboard {
  width: 100%;
  padding: 0 30px;
  background: #f9f9f9;
  /* height: 100%; */
  /* margin: 0 0 30px; */
  transition: all 0.5s;
}

.main-dashboard .dashboard-table-head {
  margin: 31px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-dashboard .dashboard-table-head .search-bar {
  width: 300px;
  position: relative;
}

.main-dashboard .dashboard-table-head .search-bar input {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #303030;
  padding: 0 15px 0 38px;
}

.main-dashboard .dashboard-table-head .search-bar span {
  position: absolute;
  top: 13px;
  left: 16px;
}

.main-dashboard .dashboard-table-head .search-bar input::placeholder {
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 500;
}

.main-dashboard .dashboard-table-head .add-recovery-btn a,
.main-dashboard .dashboard-table-head .add-subadmin-btn a {
  width: 165px;
  height: 40px;
  border-radius: 10px;
  background-color: #1980da;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  font-family: "Rubik", sans-serif;
  text-decoration: none;
}

.main-dashboard .dashboard-table-head .add-recovery-btn a img,
.main-dashboard .dashboard-table-head .add-subadmin-btn a img {
  margin: 0 7px 0 0;
}

.main-dashboard .dashboard-table-head h2 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  font-family: "Open Sans", sans-serif;
}

.main-dashboard .dashboard-table-head a {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
}

.main-dashboard.dashboard-active {
  width: calc(100% - 115px);
}

.time-period-main {
  width: 100%;
  margin: 0 0 28px;
}

.time-period-main h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 9px;
  color: #4f4f4f;
  font-family: "Rubik", sans-serif;
}

.time-period-main .timing {
  width: 100%;
  display: flex;
  align-items: center;
}

.time-period-main .timing .start-time,
.time-period-main .timing .end-time {
  width: 344px;
}

.time-period-main .timing .start-time button,
.time-period-main .timing .end-time button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0 21px;
  align-items: center;
  background: #fff;
  font-family: "Rubik", sans-serif;
  color: #bdbdbd;
  font-size: 16px;
  border: 1px solid #e0e0e0;
}

.time-period-main .timing span {
  display: block;
  margin: 0 21px;
}

.dashboard-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 0 31px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #f9f9f9;
  z-index: 1;
}

.logo-mobile {
  display: none;
}

.logo-mobile a {
  display: inline-block;
}

.logo-mobile a img {
  width: 200px;
  border-radius: 10px;
}

.bar-btn-mobile {
  display: none;
}

.dashboard-menu .logo .tab-menu-bar a.closeBtn-mobile {
  display: none;
}

.bar-btn-mobile span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.dashboard-header .dashboard-heading h2,
.dashboard-header .dashboard-heading a.dashboard-back {
  font-weight: 600;
  font-size: 26px;
  color: #333;
  margin: 0;
}

.dashboard-header .dashboard-heading a.order-id {
  font-weight: 500;
}

.dashboard-header .dashboard-heading a.dashboard-back {
  display: flex;
  align-items: center;
  width: max-content;
}

.dashboard-header .dashboard-heading a.dashboard-back img {
  margin: 0 15px 0 0;
}

.dashboard-header .profile-picture {
  width: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.dashboard-header .profile-picture .profile-img {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}

.dashboard-header .profile-picture .profile-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown {
  width: 254px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  margin: 60px 0 0;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 25%);
  z-index: 11;
  /* border: 2px solid #1980DA; */
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown.dropdown-active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .profile-info::after {
  content: "";
  position: absolute;
  top: -8px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .profile-info {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 18px 0 21px;
  position: relative;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .profile-info
  h3 {
  font-weight: 600;
  font-size: 18px;
  margin: 13px 0 0;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .profile-info
  span {
  font-size: 14px;
  color: #828282;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .profile-info
  .profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin: 0 auto;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .profile-info
  .profile-image
  img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out {
  width: 100%;
  padding: 18px 0 0 20px;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .log-out
  ul
  li {
  margin: 0 0 16px;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .log-out
  ul
  li
  a {
  font-weight: 500;
  color: #4f4f4f;
  display: flex;
  width: max-content;
  align-items: center;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .log-out
  ul
  li
  a.profile-btn {
  color: #4f4f4f;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .log-out
  ul
  li
  a.active {
  color: #eb5757;
}

.dashboard-header
  .profile-picture
  .profile-img
  .profile-dropdown
  .log-out
  ul
  li
  a
  img {
  margin: 0 11px 0 0;
  width: auto;
  height: auto;
  border-radius: initial;
}

/* table data start */
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #333;
}

.status {
  padding: 7px 22px;
  border-radius: 10px;
  background-color: #edff88;
  border: none;
  color: #413740;
  font-weight: 500;
  font-size: 12px;
}

.product-img {
  width: 50px;
  height: 50px;
}

.product-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

td.table-col {
  padding: 20px 25px !important;
}

.table-col {
  position: relative;
}

span.rating-icon {
  display: flex;
  gap: 6px;
}

td.for-order {
  padding: 0 15px;
}

td p {
  color: #828282;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 0;
}

td p img {
  margin: 0 5px 0 0;
}

td i {
  margin: 0 0 0 25px;
  color: #bdbdbd;
  cursor: pointer;
}

th.heading {
  color: #828282;
  padding: 22px 38px 22px 30px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}

.pending-btn,
.expire-btn,
span.enable-btn,
span.disable-btn {
  background-color: #fff9e7;
  color: #f2c94c;
  width: 87px;
  display: block;
  height: 28px;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

span.disable-btn {
  color: #eb5757;
  background-color: #ffe8e8;
  border: 1px solid #eb5757;
}

span.enable-btn {
  background-color: #e9fff2;
  color: #219653;
  border: 1px solid #219653;
}

.expire-btn {
  border: 1px solid #939393;
  background-color: #efefef;
  color: #939393;
}

.sold-btn {
  background-color: #fff4f4;
  color: #eb5757;
  border: 1px solid #eb5757;
  width: 87px;
  display: block;
  height: 28px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.table-col .delete-coupon,
.table-col .action-btn {
  background: none;
  border: none;
}

.for-action {
  margin: 0 0 0 15px;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
}

td.customer-profile {
  padding: 0 15px 0 0;
  width: 50px;
  height: 50px;
}

.customer-profile img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

span.service-rating {
  display: block;
  color: #828282;
  font-weight: 500;
  font-size: 12px;
  margin: 7px 0 0;
  font-family: "Rubik", sans-serif;
}

span.service-rating img {
  margin: 0 7px 0 0;
}

/* table data end */

.dashboard-main {
  width: 100%;
  display: flex;
  padding: 0 40px;
  gap: 19px;
}

.dashboard-left {
  width: 100%;
}

.dashboard-left .dashboard-main-details {
  width: 100%;
  display: flex;
  margin: 0 0 30px;
  gap: 20px;
}

.dashboard-left .dashboard-content-news {
  width: 100%;
  gap: 27px;
  justify-content: space-between;
}

.dashboard-left .dashboard-content-news .dashboard-post {
  width: 40% !important;
  background-color: #fff;
  border-radius: 10px;
}

.dashboard-left .dashboard-content-news .dashboard-post ul {
  padding: 0 18px;
}

.dashboard-left .dashboard-content-news .dashboard-post ul li {
  font-weight: 500;
  font-size: 16px;
  padding: 0 0 15px;
  border-bottom: 1px solid #b1b1b1;
  line-height: 19px;
  margin: 0 0 15px;
}

.dashboard-left .dashboard-content-news .dashboard-post ul li p {
  margin: 5px 0 0;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right, #2602ff, #a421d2);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.dashboard-left .dashboard-content-news .dashboard-post ul li p span {
  color: #dedede;
  font-weight: normal;
}

.dashboard-left
  .dashboard-content-news
  .dashboard-details-right
  .graph
  .graph-head,
.dashboard-left .dashboard-content-news .dashboard-post .graph .graph-head {
  padding: 24px 18px 18px !important;
}

.dashboard-left .dashboard-content-news .dashboard-details-right {
  width: 60% !important;
}

.dashboard-left
  .dashboard-content-news
  .dashboard-details-right
  .graph-container {
  width: 100%;
  padding: 0 18px 24px;
}

.dashboard-left
  .dashboard-content-news
  .dashboard-details-right
  .graph
  .graph-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-left
  .dashboard-content-news
  .dashboard-details-right
  .graph
  .graph-head
  select {
  background-color: transparent;
  border: none;
  background-image: linear-gradient(to right, #2602ff, #a421d2);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  outline: none;
}

/* .dashboard-left .dashboard-main-details .dashboard-details-left {
  width: 50%;
} */

.dashboard-left .dashboard-main-details .dashboard-details-left .head {
  padding: 29px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col {
  /* width: 100%; */
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col {
  width: 48%;
  float: left;
  padding: 0 12px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading {
  width: 100%;
  padding: 18px;
  background-color: #fff;
  display: flex;
  border: 1px solid #f2f2f2;
  border-radius: 14px;
  height: 120px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading {
  width: calc(100% - 49px);
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  span {
  font-weight: 600;
  font-size: 16px;
  color: #4f4f4f;
  line-height: 18px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 13px 0 0;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-color);
  margin: 17px 0 0;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  p
  span {
  font-weight: 600;
  color: var(--btn-color);
  margin: 0 0 0 7px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  p
  span.down-graph {
  color: #eb5757;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .details-img {
  width: 49px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .details-img
  span {
  width: 100%;
  height: 49px;
  background: rgba(242, 201, 76, 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .details-img
  span
  img {
  width: auto;
  height: auto;
}

.dashboard-left .dashboard-main-details .dashboard-details-right {
  width: 50%;
  display: flex;
  gap: 20px;
  border-radius: 10px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main {
  width: 100%;
  background-color: #fff;
  padding: 30px 21px 0 0;
  border-radius: 10px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .order-graph-main {
  width: 100%;
}

.dashboard-left .dashboard-main-details .dashboard-details-right .user-graph {
  width: 40%;
  background-color: #fff;
  border-radius: 10px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-head {
  padding-left: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 29px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-head
  h3,
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  h3 {
  font-weight: 600;
  font-size: 16px;
}
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  h3 {
  text-align: left;
  margin: 0 0 12px;
}
.dashboard-left .dashboard-main-details .dashboard-details-right .user-graph {
  padding: 30px 21px;
  text-align: center;
}
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  ul {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 12px 0 0;
}
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  ul
  li {
  font-size: 20px;
  color: #000;
  text-align: left;
  display: flex;
  gap: 13px;
  align-items: center;
}
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  ul
  li
  span {
  font-size: 14px;
}
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  ul
  li
  .circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #91fc9c;
}
.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .user-graph
  ul
  li
  .circle.purple {
  background-color: #9d8afa;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-head
  select {
  background-color: transparent;
  border: none;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-img {
  width: 100%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-img
  img {
  width: 100%;
}

.dashboard-left .dashboard-main-details .dashboard-details-right .graph {
  width: 100%;
  border-radius: 10px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .graph
  .graph-head {
  padding: 29px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .graph
  .graph-head
  h4,
.dashboard-left .dashboard-main-details .dashboard-details-left .head h4,
.dashboard-left .dashboard-content-news .dashboard-post .graph .graph-head h4 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .graph
  .graph-container {
  width: 100%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .graph
  .graph-container
  img {
  width: 100%;
}

.loaction-list-main {
  width: 100%;
  background-color: #fff;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  vertical-align: top;
}
.order-location-main {
  border-radius: 10px 10px 0 0;
}

.loaction-list-main .request-selection {
  width: 100%;
  padding: 0 30px;
}

.loaction-list-main .request-selection ul {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 0;
}

.loaction-list-main .request-selection ul li {
  padding: 14px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: #8b8b8b;
  cursor: pointer;
  /* border-bottom: 7px solid transparent; */
  transition: all 0.3s;
  position: relative;
}
.loaction-list-main .request-selection ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 135px;
  height: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: transparent;
}

/* .loaction-list-main .request-selection ul li.active {
    border: ;
} */
.loaction-list-main .request-selection ul li.request-active::before {
  background-color: #419485;
}
.loaction-list-main .request-selection ul li.request-active {
  color: #419485;
}

.loaction-list-main .loaction-list-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ededed;
  padding: 0 26px 0 32px;
}

.loaction-list-main .loaction-list-head .search-bar {
  width: 100%;
  max-width: 300px;
  padding: 15px 0;
  position: relative;
}

.loaction-list-main .loaction-list-head .search-bar input {
  width: 100%;
  height: 40px;
  border: 1px solid #e0e0e0;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 0 0 0 38px;
}

.loaction-list-main .loaction-list-head .search-bar input::placeholder {
  color: #bdbdbd;
  font-weight: 500;
  font-size: 12px;
}

.loaction-list-main .loaction-list-head .search-bar img {
  position: absolute;
  top: 27px;
  left: 15px;
  color: #bdbdbd;
}

.loaction-list-main .loaction-list-head .add-service {
  background-color: #4ab500;
  border: none;
  width: 121px;
  height: 40px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.loaction-list-main .loaction-list-head .add-service img {
  margin: 0 7px 0 0;
}

.loaction-list-head ul {
  display: flex;
  gap: 55px;
}

.loaction-list-head ul li a {
  font-size: 20px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #828282;
  cursor: pointer;
  display: inline-block;
  width: 136px;
  padding: 23px 0;
  position: relative;
}

.loaction-list-head ul li a.active {
  color: #4ab500;
}

.loaction-list-head ul li a.active::after {
  display: unset;
}

.loaction-list-head ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -20px;
  width: 136px;
  height: 6px;
  background-color: #4ab500;
  display: none;
  border-radius: 4px 4px 0 0;
}

.loaction-list-main .loaction-list-head .add-location {
  width: max-content;
  display: flex;
}

.loaction-list-main .loaction-list-head .add-location a {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: #f2f2f2;
  text-align: center;
  line-height: 45px;
}

.loaction-list-main .loaction-list-head .add-location .creat-coupon,
.loaction-list-main .loaction-list-head .add-location .creat-category {
  width: 138px;
  height: 40px;
  background-color: #ca1f27;
  border-radius: 10px;
  color: #fff;
  border: none;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}

.loaction-list-main .loaction-list-head .add-location .creat-coupon img,
.loaction-list-main .loaction-list-head .add-location .creat-category img {
  margin: 0 5px 0 0;
}

.loaction-list-head button.Export-btn {
  width: 130px;
  height: 36px;
  border-radius: 10px;
  background-color: #4ab500;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 6%);
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.next-previous-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 0 0;
}

.next-previous-slide .slides-heading span {
  font-size: 14px;
  color: #000000;
}

.next-previous-slide .slides-button {
  width: max-content;
}

.next-previous-slide .slides-button ul li {
  display: inline-block;
}

.next-previous-slide .slides-button ul li a {
  width: 28px;
  height: 28px;
  display: inline-block;
  color: #333333;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
}

.next-previous-slide .slides-button ul li a.active-slide {
  background: #333333;
  color: #fff;
}

/* setting page */
.setting-main {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  height: max-content;
}

.setting-main .setting-details {
  width: 100%;
  max-width: 864px;
  padding: 40px;
}

.setting-main .setting-details h3,
.add-area-info h3 {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0 0 8px;
  color: #000;
}

.setting-main .setting-details p {
  font-size: 14px;
  color: #6b7280;
  line-height: 14px;
  letter-spacing: 1px;
}

.setting-main .setting-details p span {
  font-weight: 500;
}

.customer-detail .setting-details {
  max-width: 990px;
}

.setting-main .setting-details .basic-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
  gap: 24px;
}

.setting-main .setting-details .basic-detail .profile-upload {
  width: auto;
  text-align: center;
}

.setting-main .setting-details .basic-detail .profile-upload .profile-img {
  width: 197px;
  height: 197px;
  background-color: #f6f6f6;
  position: relative;
  border-radius: 10px;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  .input-file {
  width: 42px;
  height: 42px;
  background: #419485;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  .input-file
  input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  .input-file
  img {
  width: 20px;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.setting-main .setting-details .basic-detail .profile-upload label {
  font-size: 16px;
  line-height: 16px;
  color: #6b7280;
  letter-spacing: 1px;
  margin: 13px 0 0;
  font-weight: 600;
  display: block;
}

.setting-main .setting-details .basic-detail .profile-details {
  width: calc(100% - 197px);
}

.setting-main .setting-details .save-btn {
  margin: 60px 0 0;
  padding: 16px 0 0;
  border-top: 1px solid #9ca3af;
  display: flex;
  align-items: center;
  justify-content: right;
}

.setting-main .setting-details .save-btn button {
  width: 88px;
  height: 48px;
  background: #419485;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setting-main .setting-details .basic-detail .profile-details .input-group {
  width: 100%;
  display: flex;
  gap: 24px;
  margin: 0 0 24px;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field {
  width: 47%;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  label {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #9ca3af;
  letter-spacing: 1px;
  margin: 0 0 4px;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  input::placeholder {
  font-weight: 500;
  font-size: 16px;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  input,
.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  textarea {
  width: 100%;
  height: 54px;
  border-radius: 10px;
  border: none;
  background-color: #f6f6f6;
  padding: 0 0 0 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #4b5563;
  letter-spacing: 1px;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  .password-change {
  width: 100%;
  position: relative;
}

.setting-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  .password-change
  button {
  background: none;
  border: none;
  color: #419485;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

/* mobile-home css  */
.mobile-list-main {
  background-color: transparent;
}

.mobile-home-main {
  background-color: transparent;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field {
  width: 50%;
}

.mobile-home-main .setting-details {
  max-width: 100%;
  padding: 28px 40px;
  background-color: #fff;
  border-radius: 10px;
}

.mobile-home-main .setting-details .basic-detail {
  margin: 21px 0 0;
  justify-content: left;
  align-items: center;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  textarea {
  height: 79px;
  padding: 19px 10px 0;
  font-family: "Montserrat", sans-serif;
  color: #4b5563;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  textarea::placeholder {
  font-weight: 500;
  font-size: 16px;
}

.mobile-home-main .setting-details .save-btn button {
  width: 114px;
}

.add-area-info {
  padding: 28px 40px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0 0;
}

.add-area-info .streent-areas-select {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.add-area-info .streent-areas-select .street-area-left {
  width: 300px;
}

.add-area-info .streent-areas-select .street-area-left .street-label {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 13px;
  gap: 12px;
}

.add-area-info .streent-areas-select .street-label input {
  width: 20px;
  height: 20px;
  accent-color: #419485;
}

.add-area-info .streent-areas-select .street-label label {
  margin: 0 !important;
}

.add-area-info label {
  color: #4f4f4f;
  font-weight: 500;
  font-size: 16px;
  display: block;
  margin: 0 0 9px;
}

.add-area-info .streent-areas-select .street-area-left {
  width: 300px;
}

.add-area-info h3 {
  margin: 0 0 20px;
}

.add-area-btn {
  width: 100%;
  display: flex;
  padding: 16px 0 0;
  border-top: 1px solid #9ca3af;
  margin: 30px 0 0;
  justify-content: right;
}

.add-area-btn button {
  width: 124px;
  height: 48px;
  background-color: #419485;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* borrow details */
.borrow-main .setting-details .basic-detail {
  margin: 0;
  gap: 68px;
}

.borrow-main .setting-details {
  max-width: 100%;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  label {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}

.borrow-main .setting-details .basic-detail .profile-details .borrow-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 19px;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .left {
  width: 50%;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .left
  h2 {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 600;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .left
  h2
  span {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .left
  p {
  color: #4f4f4f;
  font-weight: 500;
  margin: 4px 0 0;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .right {
  width: auto;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .right
  button {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

/* lenders page main css */
.lendors-main .setting-details .basic-detail .profile-details .borrow-head {
  max-width: 65%;
}

.lendors-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .left {
  width: 60%;
}

.borrow-main
  .setting-details
  .basic-detail
  .profile-details
  .borrow-head
  .left
  h2
  span {
  color: #efc441;
}

/* disputed order css  */
.order-list-main {
  background-color: transparent;
}

.order-tracking-main {
  width: 100%;
  display: flex;
  gap: 31px;
}

.order-tracking-main label {
  display: block;
  margin: 0 0 8px;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
  font-weight: 500;
}

.order-tracking-main .tracking-left {
  width: calc(100% - 350px);
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 18px;
}

.order-tracking-main .tracking-left .details {
  width: 100%;
  padding: 38px;
}

.order-tracking-main .tracking-left .details .input-group {
  width: 100%;
  display: flex;
  gap: 30px;
  margin: 0 0 34px;
}

.order-tracking-main .tracking-left .details .input-group .input-field {
  width: 33.33%;
}

.order-tracking-main .tracking-left .details .input-group .input-field.reason {
  width: 65%;
}

.order-tracking-main .tracking-left .details .input-group .input-field input,
.order-tracking-main
  .tracking-left
  .details
  .input-group
  .input-field
  textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 0 22px;
  outline: none;
  font-size: 16px;
}

.order-tracking-main
  .tracking-left
  .details
  .input-group
  .input-field
  textarea {
  padding: 16px 22px 0;
  height: 104px;
}

.order-tracking-main
  .tracking-left
  .details
  .input-group
  .input-field
  input::placeholder {
  color: #bdbdbd;
  font-size: 16px;
}

.order-tracking-main .tracking-left .details .feed-back {
  width: 100%;
  max-width: 80%;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  margin: 42px 0 0;
}

.order-tracking-main .tracking-left .details .feed-back .left {
  width: 50%;
}

.order-tracking-main .tracking-left .details .feed-back .rating_icon {
  display: flex;
  width: auto;
  gap: 6px;
  margin: 0 0 15px;
}

.order-tracking-main .tracking-left .details .feed-back textarea {
  width: 100%;
  height: 84px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 10px 22px 0;
}

.order-tracking-main .tracking-left .details .feed-back .rating_icon span img {
  width: 20px;
}

.order-tracking-main .tracking-left .head {
  padding: 23px 38px;
  width: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
}

.order-tracking-main .tracking-left .head h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.order-tracking-main .tracking-left .order-photos {
  width: 100%;
}

.order-tracking-main .tracking-left .order-photos .img {
  width: 100%;
  display: flex;
  /* gap: 32px; */
  justify-content: space-between;
  flex-wrap: wrap;
}

.order-tracking-main .tracking-left .order-photos .img span {
  width: 200px;
  height: 200px;
}

.order-tracking-main .tracking-left .order-photos .img span img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 0 !important;
}

.order-tracking-main .tracking-right {
  width: 350px;
}

.order-tracking-main .tracking-right h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 10px;
}

.order-tracking-main .tracking-right .return-back {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.order-tracking-main .tracking-right .return-back .head {
  background-color: #e6e8ff;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.order-tracking-main .tracking-right .return-back .head h4 {
  color: #419485;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.order-tracking-main .tracking-right .return-back .track-path {
  padding: 25px 16px;
  width: 100%;
}

.order-tracking-main
  .tracking-right
  .return-back
  .track-path
  .order-track-path {
  width: 100%;
  background-color: #f7f7f7;
  padding: 8px 12px;
  border-radius: 10px;
}

.key-chain {
  width: 100%;
  display: flex;
  margin: 0 0 14p 0x;
}

.key-chain.p-spacing {
  margin: 0;
}

.key-chain .key-chain-img {
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

.key-chain .key-chain-img::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 50%;
  /* transform: translateX(-50%); */
  width: 1px;
  height: 50px;
  background-color: #7db300;
}

.key-chain:nth-last-child(1) .key-chain-img::before {
  display: none;
}

.key-chain:nth-last-child(1) {
  margin: 0;
}

.key-chain .key-chain-img span {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.key-chain .key-chain-head {
  width: auto;
}

.key-chain .key-chain-head h3 {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 4px;
  color: #419485;
}

.key-chain .key-chain-head p {
  font-size: 14px;
  color: #419485;
  font-weight: 400;
}

.order-tracking-main .tracking-right .lendor-info {
  width: 100%;
  margin: 18px 0 0;
}

.order-tracking-main .tracking-right .lendor-info .info-detail {
  width: 100%;
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
}

.order-tracking-main .tracking-right .lendor-info .info-detail ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.order-tracking-main .tracking-right .lendor-info .info-detail ul li {
  width: 100%;
  color: #4b5563;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
}

.order-tracking-main .tracking-right .lendor-info .info-detail ul li span {
  font-weight: 600;
}

.order-tracking-main .tracking-right .lendor-info .send-btn {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: #419485;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  margin: 20px 0;
  letter-spacing: 1px;
  border: none;
}

.upload_loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.order_track-button {
  background: #e6e8ff;
  color: #419485;
  border: none;
  padding: 2px 10px;
  border-radius: 10px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
tbody {
  background: #ffffff;
}
.next-previous-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-previous-slide .slides-heading span {
  font-size: 14px;
  color: #413740;
}

.next-previous-slide .slides-button {
  width: max-content;
}

.next-previous-slide .slides-button ul li {
  display: inline-block;
}

.next-previous-slide .slides-button ul li button {
  width: 28px;
  height: 28px;
  display: inline-block;
  color: #413740;
  background: none;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
  border: none;
}

.next-previous-slide .slides-button ul li .active-slide {
  background: #413740;
  color: #fff;
  text-decoration: none;
  border: none;
}
.placeholder-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #e0e0e0;
  font-size: 21px;
  border-radius: 10px;
  font-weight: 400;
}

.track-order_details label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #4f4f4f;
  font-family: "Rubik", sans-serif;
  margin: 0 0 8px;
}

.track-order_details input {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0 19px;
}
.track-order_details textarea {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px;
}
.feedback {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
}
.feedback p {
  font-size: 16px;
  margin-top: 15px;
  color: #4f4f4f;
  display: flex;
  justify-content: space-between;
}
.feedback textarea {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px;
}
.barBtn {
  background: none;
  border: none;
}
.disabled button {
  cursor: not-allowed;
}

/* SHOP NOW FILTER PAGE CSS */

.shop-now-main {
  width: 100%;
  display: flex;
  gap: 17px;
}
.shop-now-main .shop-category {
  width: 33%;
  background-color: #fff;
  border-radius: 14px;
  padding: 20px 27px 27px;
}
.shop-now-main .shop-category h3 {
  color: #419485;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin: 0 0 33px;
}
.shop-now-main .shop-category ul {
  padding: 0;
  margin: 0;
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.shop-now-main .shop-category ul::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
.shop-now-main .shop-category ul li {
  display: flex;
  gap: 17px;
  align-items: center;
  font-weight: normal;
  margin: 0 0 19px;
  font-size: 20px;
  color: #000;
}
.shop-now-main .shop-category ul li input {
  width: 20px;
  height: 20px;
  accent-color: #419485;
}

.table-data {
  height: 64vh;
  overflow-y: auto;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-data-two {
  height: 73vh;
}
.table-data::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
.mobile-home-main .setting-details .basic-detail .profile-details {
  width: 50%;
}
.mobile-home-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field {
  width: 100%;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-details
  .input-group
  .input-field
  textarea {
  height: 200px;
}
.mobile-home-main .setting-details .basic-detail .profile-upload {
  display: flex;
  gap: 15px;
}
.mobile-home-main
  .setting-details
  .basic-detail
  .profile-upload
  .upload-img-mobile {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  border: 1px dashed #000000;
}
.mobile-home-main
  .setting-details
  .basic-detail
  .profile-upload
  .upload-img-mobile
  button {
  position: absolute;
  background: transparent;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-home-main .setting-details .basic-detail .profile-upload .profile-img {
  display: flex;
  width: auto;
  gap: 10px;
  background: transparent;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  .relative {
  width: 200px;
  position: relative;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  .relative
  button {
  position: absolute;
  bottom: 0;
  border: none;
  right: 0;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.mobile-home-main
  .setting-details
  .basic-detail
  .profile-upload
  .profile-img
  .relative
  img {
  width: 100%;
}

.mobile-home-main .setting-details .basic-detail .profile-upload {
  width: calc(100% - 200px);
}
.banner-image-new {
  display: flex;
  gap: 15px;
}
.banner-image-new .profile-img {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 15px;
}
.banner-image-new .profile-img .relative {
  position: relative;
  width: 250px;
  height: 200px;
}

.banner-image-new .profile-img .relative img {
  object-fit: cover;
}

.banner-image-new .profile-img .relative button {
  position: absolute;
  bottom: 0;
  border: none;
  right: 0;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.banner-image-new .upload-img-mobile {
  width: 250px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background: #defff9;
  cursor: pointer;
  border: 1px dashed #000000;
}

.banner-image-new .upload-img-mobile button {
  position: absolute;
  background: transparent;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner-image-new .upload-img-mobile input {
  cursor: pointer;
}
