/* Modal Overlay */

/* Modal Content */
.modal-contentmodal-content {
  background-color: white;
  border-radius: 10px;
  position: fixed;
  width: 330px !important;
  height: 225px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.change_password-modal {
  background-color: white;
  border-radius: 10px !important;
  position: fixed;
  height: 460px !important;
  width: 360px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.pay_password-modal {
  background-color: white;
  border-radius: 10px !important;
  position: fixed;
  height: 380px !important;
  width: 400px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Modal Icon */
.modal-icon {
  font-size: 24px;
  color: #fff;
  background: #ca1f27;
  border-radius: 50%;
  padding: 4px;
  width: 35px;
  height: 35px;
}

/* Modal Close Button */
.modal-close-btn {
  background-color: transparent;
  color: #419485;
  border: none;
  border-radius: 10px;
  width: 80px;
  height: 36px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  transition: background-color 0.3s;
}
.modal-change-btn {
  background: #419485;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 800;
  width: 90px;
  height: 45px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-not-btn {
  background: transparent;
  color: #419485;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 800;
  width: 90px;
  height: 45px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.modal-confirm-btn {
  background-color: #419485;
  color: #fff;
  border: none;
  border-radius: 10px;
  width: 80px;
  height: 36px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  transition: background-color 0.3s;
}

/* Modal Text */
.modal-body h2 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}
.modal-body {
  margin-top: 10px;
}

.modal-body p {
  margin-bottom: 10px;
  color: #828282;
  line-height: 20px;
}
/* .no-scroll {
    overflow: hidden !important;
  } */
.change_password-modal .modal-body input {
  width: 100%;
  height: 40px;
  padding: 20px;
  border-radius: 10px;
  border: none;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #E0E0E0;
}
.change_password-modal .modal-body label {
  margin: 8px 0;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #4f4f4f;
}

.modal-body textarea{
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 10px;
}